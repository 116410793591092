<template>
   <li class="obi-dropdown-divider dropdown-divider" v-bind="attrs"></li>
</template>

<script>
import { omit, keys } from 'lodash';

export default {
   name: 'ObiDropdownDivider',
   props: [],
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-dropdown-divider {
}
</style>
